.bodyContainer{margin:0px 0px 100px;}
.homeContainer{
    margin: 0px 30px 40px;
}
.nctable{
    border-collapse: collapse;
    width: 100%;
    margin: 0 auto;
}
.nctable td, .nctable th {
  border: none;
  padding: 15px 0px;
  text-align: left;
  color: #697882;
  font-size: 14px;
  width: 16%;
}
.nctable th {
  background-color: #EAEAEA;
  color: #686868;
  font-size: 14px;
}
.nctable tr{border-bottom: 1px solid #EBF0F4;word-break: break-word;}
.nctable tr.loader, .nctable tr.no-data-found{border: none;}
.nctable tr.no-data-found td{padding: 30px 0px;}
.nctable th input, .nctable th select{border: 1px solid #626262; border-radius: 2px; padding: 4px 10px;}
.nctable th input:focus, .nctable th input:hover, .nctable th select:focus, .nctable th select:hover {outline: none!important;}
.badge{
  border-radius: 23px;
  font-weight: 600;
  padding: 5px 0;
  width: 100px;
  display: inline-block;
}
.status-active{
  color: #069B01;
  background-color: #E9F9F0;
}
.status-inactive{
  color: #00B0FF;
  background-color: #DCF2FB;
}
.status-declined{
  color: #CB2B1D;
  background-color: #FFE0E0;
}
.status-awaiting_activation{
    color:#DD7D31;
    background-color: #FFE7D5;
}
.status-suspended{
  color: #FFC20F;
  background-color: #FFF6DD;
}
.fa-icon{
    font-size: 18px;
    color: #037DB8;
    cursor: pointer;
}
.fa-icon2{
  font-size: 12px;
  color: #037DB8;
  cursor: pointer;
}
.nctable th:nth-child(1), .nctable td:nth-child(1){
  text-align: center;
  min-width: 45px !important;
}
.nctable th:nth-child(2), .nctable td:nth-child(2),.homeContainer .nctable th:nth-child(5), .homeContainer .nctable td:nth-child(5){
  word-break: break-word; padding-right: 30px;
}
.homeContainer .nctable th:nth-child(3), .homeContainer .nctable td:nth-child(3) {
  text-align: center;
  min-width: 110px !important;
  width: 13%;
}
.nctable th:nth-child(2), .nctable td:nth-child(2), .nctable th:nth-child(4), .nctable td:nth-child(4),
.nctable th:nth-child(5), .nctable td:nth-child(5) {
  min-width: 100px !important;
}
.nctable th:last-child, .nctable td:last-child {
  /*width: 18% !important;*/ /*This width is used to calculate width of other columns, search with different child .nctable th:nth-child(n) */
}
.worklist th:last-child, .worklist td:last-child {
  text-align: center;
}
.colAction > a{
  font-size: 14px;
  color: #037DB8;
  text-decoration: underline;
  margin: 0px 15px 0px 15px;
  text-transform: capitalize;
  display: inline-block;
}
.colAction > a:first-child {
  margin: 0px 15px 0px 0px;
}
.colAction > a:nth-child(2) {
  margin: 0px 0px 0px 15px;
}
.colAction > a:last-child{
    padding-left: 0px;
}
.colAction > a:only-child{
  margin-right: 0px;
}
.colPendingFrom span{vertical-align: top; margin-right: 8px;}
.colPendingFrom .fa-icon{font-size: 15px; margin-top: 2px;}
.nctable-paginate-info{font-size: 12px; padding: 20px 40px;color:#686868;font-weight: 600;}
.nctable-info{float:left;}
.nctable-paginate{float:right; display: flex;}
.prevbutton > button, .nxtbutton > button {border: none; background: none;}
.prevbutton > button:disabled > .fa-icon, .nxtbutton > button:disabled > .fa-icon{color:#666666; cursor: default;}
.pageNum > input{text-align: center; width:20px;background: #E5F3FF 0% 0% no-repeat padding-box;border: 1px solid #3A7CB3;border-radius: 3px;}

.TableScrollbar::-webkit-scrollbar-track
{
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #DFE6ED;
}
table tbody {
  display: block;
  max-height: calc(70vh - 150px);
  overflow-y: auto;
}
table thead, table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
table tbody::-webkit-scrollbar
{
  width: 5px;
  background-color: #DFE6ED;
}

table tbody::-webkit-scrollbar-thumb
{
  background-color: #788791;
  border: 2px solid#788791;
}
.more-less-content a{text-decoration: none;}
table input, table select {
  width: 104px;
}
.nctable thead select{color: #707070;}

.margin-top-zero {
  margin-top: 0px;
}

/* Register New User Page */
.reg-nw-user{width:45%;margin: 0px auto 60px;}
.reg-nw-user h1{font-size: 24px; font-weight: bold; text-transform: capitalize;color: #2D373C;}
.reg-nw-user-form label{font: normal normal 600 14px/19px Open Sans Regular; letter-spacing: 0px; color: #037DB8;}
.reg-nw-user-form label span{color: #EB1922;}
.reg-nw-user-form .form-control{width:95%;border: 1px solid #626262;border-radius: 2px;padding: 8px 12px;font-size: 14px;color: #707070;}
.reg-nw-user-form .input-error{border: 0.800000011920929px solid #D53630;}
.input-err-msg{color:#EB1922;font-size: 12px;display: block;position: absolute;}
.input-err-msg::before {
  display: block;
  content: "!";
  border-radius: 50%;
  border: 1px solid #EB1922;
  background-color: #EB1922;
  width: 10px;
  height: 10px;
  line-height: 11px;
  text-align: center;
  color: #FFFFFF;
  font-weight: normal;
  font-size: 10px;
  margin-right: 5px;
  margin-top: 3px;
  float: left;
}
.input-err-msg::first-letter {text-transform: uppercase;}
.reg-nw-user-form .form-control{margin-top:9px;}
.reg-nw-user-form .form-control:focus, .form-control:hover {outline: none!important;}
.reg-nw-user-form .form-grp-2 .form-control{width:90%;}
.reg-nw-user-form select{width:100% !important;}
.reg-nw-user-form .grid-container{display: grid;grid-template-columns: 1fr 1fr;grid-gap: 20px;}
.reg-nw-user-form .form-group{margin-bottom: 25px;}
.btn{border: 1px solid #3579B0; border-radius: 2px; opacity: 1; width: 116px; padding: 6px 30px; font: normal normal 600 14px/19px Open Sans Regular; letter-spacing: 0px;cursor: pointer; text-transform: capitalize;}
.white-btn{color: #037DB8; background-color: #FFFFFF;}
.blue-btn{
  color: #FFFFFF;
  background: transparent linear-gradient(270deg, #3A7CB3 0%, #19669B 43%, #005488 100%) 0% 0% no-repeat padding-box;
}
.reg-nw-user-form .btn-grp button:first-child, .confirm-dialog-modal .btn-grp button:first-child{margin-right: 10px;}
.response-msg-div{padding:14px 30px;}
.response-msg-div.success-msg{background-color: #F4FFFA; color:#047a45; margin-bottom:25px;}
.response-msg-div.err-msg{background-color: #ffe0e0;color: #eb1922; margin-bottom: 25px;}
.resp-msg{font: normal normal normal 20px/20px Open Sans Regular; display: inline-block;}
.resp-msg::first-letter{text-transform: capitalize;}
.response-msg-div .icon-close{font-size:22px; vertical-align:middle; float:right;cursor: pointer;}
.success-msg .resp-msg::before {
  display: block;
  content: "\2713";
  border-radius: 50%;
  border: 1px solid #047a45;
  background-color: #047a45;
  width: 20px;
  height: 20px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
  font-weight: normal;
  font-size: 18px;
  margin-right: 10px;
  float: left;
}
.err-msg .resp-msg::before {
  display: block;
  content: "!";
  border-radius: 50%;
  border: 1px solid #EB1922;
  background-color: #EB1922;
  width: 20px;
  height: 20px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
  font-weight: normal;
  font-size: 18px;
  margin-right: 10px;
  float: left;
}

/* page-not-found */
.page-not-found{width:100%;text-align: center;display: flex;height: 60vh;justify-content: center;}
.pnf-inner{align-self: center;}
.page-not-found h1{font: normal normal bold 90px/18px Open Sans Regular;letter-spacing: 7.2px;color: #2D373C;display:inline-block; margin-bottom:38px;}
.page-not-found h2{font: normal normal 600 40px/18px Open Sans Regular;letter-spacing: 2px;color: #727272;}
.page-not-found p{font: normal normal 600 16px/18px Open Sans Regular;letter-spacing: 0px;color: #727272;}

.textareaField {
  resize: none;
  height: 100px;
}
.textareaField::-webkit-scrollbar-track
{
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #DFE6ED;
}
.textareaField::-webkit-scrollbar
{
  width: 5px;
  background-color: #DFE6ED;
}

.textareaField::-webkit-scrollbar-thumb
{
  background-color: #788791;
  border: 2px solid#788791;
}

/* My Worklist */
.wrapper{margin:0px 30px 40px;}
.react-tabs__tab-list{border:none;}
.react-tabs__tab{
  border:none;
  padding: 6px 0px;
  margin-right:25px;
  font: normal normal 600 16px/20px Open Sans Regular;
  letter-spacing: 0px;
  color: #037DB8;
}
.react-tabs__tab--selected{border-bottom: 1px solid #037DB8;}
.approve-client-app .back-btn{float:right}
.btn-grp {
  margin-top: 36px;
}

/* Confirm Dialog Box */
.confirm-dialog-modal{
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}
.confirm-dialog-box{
  width: 35%;
  font: normal normal normal 14px/19px Open Sans Regular;
  letter-spacing: 0px;
  background-color: #c6c6c6;
  margin: auto;
  border: 1px solid #888;
  box-shadow: 0px 5px 26px #2F6090;
  border-radius: 6px;
  opacity: 1;
}
.confirm-dialog-box-inner{
  min-width:206px;
  color: #697882;
  text-align: center;
  letter-spacing: 0px;
  background-color: #fefefe;
  padding: 43px 70px 60px;
  border-radius: 6px;
  opacity: 1;
}
.confirm-dialog-box .close{float:right; font-size:22px; padding:14px; color:#037DB8;cursor: pointer;}
.confirm-dialog-box-inner p{
  width: 68%;
  padding: auto;
  margin: 20px auto 0px;
}
.confirm-dialog-box-inner p::first-letter{text-transform: capitalize;}
/* Tooltip */
.tooltip {
  position:relative;
}
.tooltip:before {
  content: attr(data-tip);
  position:absolute;
  top:50%;
  transform:translateY(-50%);
  left:initial;
  margin:initial;
  right:100%;
  margin-right:11px;
  width:fit-content;
  padding:6px;
  border-radius:3px;
  background: transparent linear-gradient(180deg, #3A7CB3 0%, #19669B 43%, #005488 100%) 0% 0% no-repeat padding-box;
  color: #fff;
  font: normal normal 600 9px/13px Open Sans Regular;
  text-align:center;
  word-break: normal;
  display:none;
}
.tooltip:after {
  content: "";
  position:absolute;
  right:100%;
  margin-right:-1px;
  top:45%;
  transform:translateY(-50%);
  border:6px solid #19669B;
  border-color: transparent transparent transparent #19669B;
  display:none;
}
.tooltip:hover:before, .tooltip:hover:after {
  display:block;
}
.tooltip-container{
  font-size: 14px;
}
.not-found-icon{
  font-size: 85px;
  color: #2D373C;
}
.not-found-para1{color: #727272; font: normal normal 600 40px/18px Open Sans Regular; letter-spacing: 2px;}
.not-found-para2{color: #727272; font: normal normal 600 16px/18px Open Sans Regular; letter-spacing: 0px;}
#loginModal{padding-top:25vh;}
#loginModal .confirm-dialog-box{font:normal normal normal 16px/21px Open Sans Regular;}
.blank-loader{position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); -webkit-transform: translate(-50%, -50%); }
.checkbox-row {display: flex;}
.checkboxContainer label {cursor: pointer;}
div.checkboxContainer .checkboxContainerLabel {display: flex; align-items: center; color: #037DB8}
div.checkboxContainer{font-size:14px;font-weight:400;color:#000;position:relative;display:flex;align-items:center;padding:6px 0;cursor: pointer;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;}
label.checkboxContainer{font-size:14px;font-weight:400;color:#000;position:relative;display:flex;flex-direction: row-reverse;justify-content:flex-end;align-items:center;padding:6px 0;cursor: pointer;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;}
.checkboxContainer .accordionCheckBox {position: absolute;opacity:0;cursor: pointer;height:14px;width:14px;margin:0;}
.checkboxContainer .checkmark {position:relative;height:12px;width:12px;border:1px solid #9D9D9D;margin-right:22px;}
.checkboxContainer .accordionCheckBox:checked ~ .checkmark {background: #000;}
.checkboxContainer .accordionCheckBox:checked ~ .checkmark:after {position:absolute;content:'';left: 4px;top: 1px;width: 3px;height:7px;border: solid white;border-width: 0 1px 1px 0;-webkit-transform: rotate(45deg);-ms-transform: rotate(45deg);transform: rotate(45deg);}
.checkboxContainer.disabled, .checkboxContainer .accordionCheckBox:disabled{outline: none;cursor: default;pointer-events: none;}
.reg-nw-user-form label.paymentMethodLabel{display: block;padding:15px 0 25px;font-weight: bold;font-size: 24px;color: #2D373C;}
.reg-nw-user-form label.paymentMethodLabel > span{font-size: 14px;}
.accordion.form-control{padding:0;width:100%;}
.accordion-title{font-size:12px;font-weight:400;color:#000;}
.accordion-item .accordion-title.hide{display:none;}
.accordion-item{padding: 8px 12px;}
.accordion-item:last-child{border:0;}
.accordion-item.border{border-top: 1px solid #E4D4D4;}
.accordion-item .accordion-title{display:flex;align-items:center;color:#000;font-weight: 600;cursor:pointer;padding-bottom:10px;}
.accordion-item .accordion-title.disabled{cursor: auto;}
.accordion-item .accordion-label{padding-left:15px;}
.accordion-item .accordion-content{-webkit-transition: all 1s ease-in-out;-moz-transition: all 0.3s ease;transition: all 0.3s ease;height:0px;opacity: 0;overflow: hidden;}
.accordion-item .accordion-content.open{opacity:1;height: auto;padding-left:27px;}
.accordion-item:empty{padding:0 !important;border:0;}
.BB{opacity:0;background: yellow;}
.AAA{opacity:0;background: green;}
.AAA,.BB{height:0;}
.accordionIcon{width:12px;height:12px;position: relative;display: flex;justify-content: center;align-items: center;}
.accordionIcon.accordionMinus{width:12px;height: 2px;background: #2D373C;}
.accordionIcon.accordionPlus::before{position: absolute;content:'';width:2px;height:12px;background:#2D373C;}
.accordionIcon.accordionPlus::after{position: absolute;content:'';width:12px;height:2px;background:#2D373C;}
.region-container { display: inline-block;}
ul.region{display:flex;list-style-type: none;margin:0;padding:0;}
ul.region li{border: 1px solid #857B7B;border-top-left-radius: 10px;border-top-right-radius: 10px;background: #D9D9D9;white-space: nowrap;padding: 10px;font-size:14px;cursor:pointer;}
ul.region li.active{background:#FFF;}
.accordion-box{border:1px solid #857B7B;padding:10px;}
#paymentDTOs{margin:0;border-color:#E4D4D4;max-width:85%;border-top:0;}