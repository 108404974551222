.tooltip-container {
    position: relative;
    display: inline-block;
    margin-left: 2px;
}

.tooltip {
    position: absolute;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    opacity: 0.9;
    z-index: 1;
    white-space: nowrap;
    display: none;
    font: normal normal 600 14px/19px Open Sans Regular;
    letter-spacing: 0px;
}

.tooltip-container:hover .tooltip {
    display: block;
}